import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "국회의원 숫자, 몇 명이 적당할까?",
  "slug": "data8",
  "date": "2022-03-14T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb08.png",
  "featuredImg": "../images/featured/featured08.png",
  "summary": "풍부한 정보를 공유한 후 숙의하고 토론해 결정한다면 어떤 결과 가 나올까? 국회의원 정수와 관련해 숙의형 웹조사를 실시해봤다.",
  "tags": ["정치", "국회의원", "숙의형조사"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`한국 정치사에서 정치 개혁은 무엇인가를 버리고 절감하고 없애는 데 초점을 맞춘 경향이 있다. 작게 만들어 문제의 소지를 줄이는 게 낫다고 여긴 탓이다. 사회경제 적 쟁점의 결론도 축소 또는 폐지로 이어진 것이 많다. 이러한 여론의 흐름은 국회 의원 정수에 있어서도 마찬가지로 드러난다. 대부분의 여론조사 결과에서 국회의원 숫자를 늘리는 데 반대하는 여론이 평균 60~70%에 달한다. 그런데 즉각적인 반응이 아니라 더 풍부한 정보를 공유한 후 숙의하고 토론해 결정한다면 어떤 결과 가 나올까? 국회의원 정수와 관련해 숙의형 웹조사를 실시해봤다.`}<br parentName="p"></br>{`
`}{`숙의형 웹조사는 무엇이 다수 결정인가보다는 '무엇이 현명한 결정인가에 초점을 맞춘다. 그래서 해당 이슈에 대한 정보 공유와 숙의 토론의 효과를 얻기 위해 자신 과 다른 주장에 계속 노출되도록 설계됐다. 숙의조사의 타당성과 효과는 완전한 검 증이 이뤄지지 않았지만 2017년 10월 신고리 5·6호기 원전공론화위원회 공론조사에 두 달 앞서 실시한 숙의형 웹조사에서, 공론조사의 실제 결과와 비슷한 결과를 도출한 것을 볼 때 타당성 있는 조사 방식으로 참고하기에 충분하다고 보인다.`}</p>

    <h2>{`국회의원 정수를 어떻게 하는 게 바람직하다고 보는가?`}</h2>
    <ChartBase type={'bar'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <h3>{`국회의원 정수 조정에 관한 숙의 후 판단 결과`}</h3>
    <p>{`사전 조사에서는 국회의원 정수 감축이 36%로 가장 높은 반응을 보였고, 확대 (31%), 현재 수준 유지(29%) 순이었다. 확대와 감축만을 놓고 답하게 했을 때는 감축이 62%, 확대가 38%였다.`}<br parentName="p"></br>{`
`}{`그런데 정보 제공 및 숙의 과정을 거친 후 응답 결과가 바뀌었다. 확대가 10%p 증가했고, 유지는 9%p 감소했다. 축소도 1%p 증가했다. 변화된 최종 여론이 확대와 축소의 양 갈래로 수렴됐다는 점이 눈에 띈다. 이 결과로 볼 때 정보 제공과 숙의 과정이 분명한 의사결정에 도움을 주었다고 할 수 있다. 이는 그동안의 의사결정이 충분한 검토 없이 이뤄졌다는 반증이기도 하다.`}</p>
    <h6>{`*2019년 2월 13~14일, 전국 성인남녀 700명을 대상으로 국회의원 정수와 관련한 숙의형 웹조사를 진행했다. <경향신문>과 공공의창이 기획하고 여론조사기관 우리리서치가 조사를 수행했다. 95% 신뢰수준에 최대 허용오차는 ±3.7%p다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      